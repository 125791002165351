import React from 'react'
import partnerLogo_1 from "../assets/images/partners_logo/crop/raydium-logo.png";
import partnerLogo_2 from "../assets/images/partners_logo/crop/solana.png";
import partnerLogo_3 from "../assets/images/partners_logo/crop/Fluxbeam.png";
import partnerLogo_4 from "../assets/images/partners_logo/crop/Dexscreener.png";
import partnerLogo_5 from "../assets/images/partners_logo/crop/Birdeyes.png";
import partnerLogo_6 from "../assets/images/partners_logo/crop/coinranking-logo.png";
import partnerLogo_7 from "../assets/images/partners_logo/crop/raydium-logo.png";
import partnerLogo_8 from "../assets/images/partners_logo/crop/solana.png";
const partnerLogo = [
    partnerLogo_1,
    partnerLogo_2,
    partnerLogo_3,
    partnerLogo_4,
    partnerLogo_5,
    partnerLogo_6,
    partnerLogo_7,
    partnerLogo_8,
];

const Partner = () => {
    return (
        <>
            <div className='partnerr'>
                <div className="container py-4">
                    <div className="row partner  d-flex flex-nowrap justify-content-between">
                        {partnerLogo.map((logo, index) => (
                            <img key={index} className="image" src={logo} alt="" />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partner;