import React from 'react'

const Roadmap = () => {
    return (
        <>
            <div className="check">
                <h1 className='text-center text-uppercase mt-3 mb-0'>roadmap v1 2024</h1>
                <div className="checkpoints">
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non illum deleniti distinctio
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                    <div className="checkpoint">
                        <div>
                            <h5>Jan,05 2024</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem expedita nemo, amet molestiae a
                                non
                                doloribus expedita.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Roadmap