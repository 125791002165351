import React from 'react'

const Steps = () => {
    return (
        <>
            <div className="features steps" >
                <div className="container-fluid py-3">
                    <div className="row" data-aos="zoom-in-down" data-aos-duration="2000">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <small className="color fw-bold py-3">Ready To Get Starte?</small>
                            <h1 className="fw-bold text-uppercase">3 steps to start</h1>
                            <span className="span">There are many variations of passages of ipsum available,but the
                                majority
                                have suffered alteration in some form.</span>
                        </div>
                        <div className="py-4 steps_data">
                            <div className="safe step text-center">
                                <span>01</span>
                                <div className='data text-start'>
                                    <h5>Create Your Wallet</h5>
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                </div>
                            </div>
                            <div className="safe step text-center">
                                <span>02</span>
                                <div className='data text-start'>
                                    <h5>Make Payment</h5>
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                </div>
                            </div>
                            <div className="safe step text-center">
                                <span>03</span>
                                <div className='data text-start'>
                                    <h5>Buy & Sell Coins</h5>
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Steps