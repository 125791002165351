import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'

const Piechart = () => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        const myChartRef = chartRef.current.getContext('2d');


        chartInstance.current = new Chart(myChartRef, {
            type: 'pie',
            data: {
                labels: ["Staking program", "Presale or LP", "CEX Listing", "Integration V1", "Team"],
                datasets: [
                    {
                        data: [7650000000, 3060000000, 1530000000, 1530000000, 1530000000],
                        backgroundColor: [
                            '#fea72b',
                            '#464682',
                            '#717140',
                            '#3f3f3f',
                            'pink'
                        ],
                    }
                ]
            }
        })
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        }
    });

    return (
        <>
            <div className='container p-0' data-aos="zoom-in-down" data-aos-duration="2000">
            <h1 className='text-center text-uppercase py-3 fw-bold' >bitcoinnano tokenomic</h1>
                <div className='chart' data-aos="zoom-in-down" data-aos-duration="2000" >
                    <div className='piechart'>
                        <canvas ref={chartRef} />
                    </div>
                    <div className='piechart_data'>
                        <div className="chartdata">
                            <h3 className="fw-bold ">50% Staking To Earn: 10.50 Billions NANO</h3>
                            <span className="ms-3">- Staking NFT to Earn NANO tokens</span>
                            <span className="ms-3">- Staking NANO to Earn NANO tokens</span>
                        </div>
                        <div className="chartdata">
                            <h3 className="fw-bold ">20% Presale & LP: 4.20 Billions NANO </h3>
                            <span className="ms-3">- 2.10B NANO tokens airdrop to Presale Buyers</span>
                            <span className="ms-3">- A pair Sol+NANO add to LP & Locked 1 years</span>
                        </div>
                        <div className="chartdata">
                            <h3 className="fw-bold ">10% CEX Listing : 2.10 Billion NANO</h3>
                            <span className="ms-3">- - 5% reserved for Tier 2</span>
                            <span className="ms-3">- 5% reserved for Tier 1</span>
                        </div>
                        <div className="chartdata">
                            <h3 className="fw-bold ">10% Integration From V1: 2.10 Billion NANO</h3>
                            <span className="ms-3">- Airdrop to the V1 holders (Ratio: 10=1)</span>
                            <span className="ms-3">- Airdrop to the Community (Season 2)</span>
                        </div>
                        <div className="chartdata">
                            <h3 className="fw-bold ">10% Team Tokens: 2.10 Billion NANO</h3>
                            <span className="ms-3">- Linear Vesting for 10 months</span>
                            <span className="ms-3">- 210m tokens vesting every months</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Piechart