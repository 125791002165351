import React from 'react'

const Contact = () => {
    return (
        <>
            <div className='privacy'>
                <div className='container'>
                    <div className='row py-5'>
                        <h1 className='text-center'>Contact Us</h1>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row py-5 d-flex justify-content-center '>
                    <div className='col-xl-6 headerr rounded-3 p-3'>
                        <label className='form-label text-light'>Name</label>
                        <input className='form-control mb-3' type='text' placeholder='Enter your Name'/>
                        <label className='form-label text-light'>Email</label>
                        
                        <input className='form-control mb-3' type='email' placeholder='Enter your email address'/>
                        <label className='form-label text-light'>Phone No.</label>

                        <input className='form-control mb-3' type='number' placeholder='Enter your Phone Number'/>
                        <label className='form-label text-light'>address</label>
                        <input className='form-control mb-3' type='text' placeholder='Enter your address'/>
                        <button className='btn bg-color fw-bold color-text'>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact