import React from 'react'
import nanoCoinLogo from "../assets/images/nanoCoin.png";
import btcLogo from "../assets/images/btc.png";
const Missionvision = () => {
    return (
        <>
            <div className='missionvision py-3'>
                <div className="container">
                    <div className=" vision " data-aos="fade-left" data-aos-duration="2000">
                        <div className="vision-1">
                            <div className="vision-2">
                                <div className="vision-3 d-flex justify-content-center align-items-center">
                                    <h1 className="fw-bold fs-3 text-light" >Our Vision</h1>
                                </div>
                            </div>
                        </div>
                        <div className=" p-0 d-flex align-items-center vision-list">
                            <ul className="fs-5 ps-0" >
                                <li className="mb-2 ms-2">To be the top 100th Global Digital Payment System</li>
                                <li className="mb-2 ms-2">To be the first & Biggest Bitcoin’s Smart Phone Distribution Globally</li>
                                <li className="mb-2 ms-2">To be the Top 10th RWAs Marketplace at Thailand & UAE</li>
                                <li className='ms-2'>To be achieved our goal 1,000,000 NANO {" "}
                                    <img
                                        className=""
                                        src={nanoCoinLogo}
                                        alt="bitcoinnano-logo"
                                    />{" "}
                                    = 1 BTC{" "}
                                    <img className="" src={btcLogo} alt="btc-logo" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-5 vision" data-aos="fade-right" data-aos-duration="2000">
                        <div className=" p-0 d-flex align-items-center vision-list">
                            <ul className="fs-6" >
                                <li className="mb-3">PayNANO (Payment Platform) strives to be the best trusted escrow services payment
                                    platform provider by creating strong partnership with the twitter promoter, Youtube
                                    influncer, & Telegram group callers…. And committed to our customers & partners across
                                    all the Crypto World. We aim for the optimally, effective, safe, seamless, accessible, and
                                    socially efficient payment solutions where innovation and technology are important
                                    elements.</li>
                                <li className="mb-3">A world Class Bitcoin’s SmartPhone, To brought the physicals asset with the technology to
                                    the Cryptocurrency & Blockchain era….. A one stop mobile, want to offer smartphone
                                    users, comfort, convenience, and fun when they use the device. Our main software focus
                                    on the Exchange Wallets, Dex, Blockchain Data, Crypto News & Payment……etc.</li>
                                <li className="mb-3">To engage the real estate, vehicles & physicals companies or an individual where they
                                    want to buy/sell their real world asset to be at one stop service platform with trustworthy,
                                    seamless, accessible and simple UX….. Where we will start our first location journey from
                                    Bangkok, Thailand & Dubai UAE
                                </li>
                            </ul>
                        </div>
                        <div className=" vision-head" >
                            <div className="vision-1">
                                <div className="vision-2">
                                    <div className="vision-3 d-flex justify-content-center align-items-center">
                                        <h1 className="fw-bold fs-3 text-light" >Our Mission</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Missionvision;