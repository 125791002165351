// import React from 'react'
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logoBitcoinnano.png";
import whitepaperPDF from "../assets/files/Bitcoinnano_Whitepaper.pdf";
import tokenomicsExcel from "../assets/files/Tokenomics.xlsx";

const Footer = () => {
    const downloadFileURL = (url, filePath, fileName) => {
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.setAttribute('target', '_blank');
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);
        const downloadTag = document.createElement('a');
        downloadTag.href = filePath;
        downloadTag.setAttribute('download', fileName);
        document.body.appendChild(downloadTag);
        downloadTag.click();
        document.body.removeChild(downloadTag);
    };

    return (
        <>
            <div className="footerr">
                <div className="container">
                    <div className="data">
                        <div className="logo py-3">
                            <img src={logo} alt="" />
                            <span className="text-light py-4"><i className="bi bi-c-circle"></i>&nbsp;2024 Bitcoinnano.All Rights Reserved</span>
                        </div>
                        <ul>
                            <NavLink to='/About-Us'><li>About Us</li></NavLink>
                            <NavLink to='/Privacy-Policy'><li>Privacy & Policy</li></NavLink>
                            <NavLink to='/Contact-Us'><li>Contact Us</li></NavLink>
                            <NavLink to='/Terms-of-Use'><li>Terms Of Use</li></NavLink>
                        </ul>
                        <div className="right">
                            <div className="iconss">
                                <i className="bi bi-telegram"></i>
                                <i className="bi bi-telegram"></i>
                                <i className="bi bi-twitter-x"></i>
                                <i className="bi bi-google"></i>
                            </div>
                            <div className="download">
                                <h5 onClick={() => { downloadFileURL('docs/download-docs', whitepaperPDF, 'Bitcoinnano_Whitepaper.pdf') }} >Whitepaper</h5>
                                <h5 onClick={() => { downloadFileURL('docs/download-docs', tokenomicsExcel, 'Tokenomics.xlsx') }} >Tokenomics</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer