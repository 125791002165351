import React from 'react';
import nanoCoinLogo from "../assets/images/nanoCoin.png";
import nanoBox from "../assets/images/nanoBox.svg";
import btcLogo from "../assets/images/btc.png";
import bitcoinnet from "../assets/images/bitcoin-net.png";
import solana from "../assets/images/partners_logo/crop/solana-sol-logo.png";
const Intro = () => {
  return (
    <>
      <div className="intro">
        <div className="container-fluid p-0 bg-none">
          <div className="intro_data">
            <div className=" intro-left h-85 d-flex align-items-center justify-content-center" data-aos="slide-right" data-aos-duration="2000">
              <img className="img1" src={nanoBox} alt="" />
              <img className="img2" src={bitcoinnet} alt="" />
            </div>
            <div className="intro-right h-85" data-aos="fade-left" data-aos-duration="2000">
              <h1 className="text-capitalize ">The meme coin with utility on solana <img className="coinlogo" src={solana} alt="" />{" "} & bitcoin {" "} <img className="coinlogo" src={btcLogo} alt="" />{" "} ecosysytem</h1>
              <h1 className="color">1,000,000 Nano{" "}
                <img className="coinlogo" src={nanoCoinLogo} alt="" />{" "}
                = 1 BTC{" "}
                <img className="coinlogo" src={btcLogo} alt="" />{" "}
                </h1>
              <p className="pb-3 p-2 fw-bold mt-4">
                BitcoinNANO(NAN0) is a memecoins with utilities on Solana & Bitcoin Ecosystem{" "}
                ,
                By explorer its utilities BitcoinNANO is brought you to a world class Bitcoin’s Smart Phone with
                a unique Payment Platform (PayNANO){" "}
                . Beyond a memecoins it has a unique NFT, Staking to Earn program and RWAs Marketplace where its
                startstrategic location from Bangkok, Thailand to Dubai, UAE.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Intro;