import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import phone from "../assets/images/Artboard 1 copy 2.jpg";

import 'swiper/css';
import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// import required modules
import { Pagination } from 'swiper/modules';
const Ourtest = () => {
    const [swiperConfig, setSwiperConfig] = useState({
        slidesPerView: 3,
        spaceBetween: 58,
        loop: true,
        pagination: {
            clickable: true,
        },
        modules: [Pagination],
    });

    // Function to update Swiper configuration based on window size
    const updateSwiperConfig = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 480) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 1,
                spaceBetween: 30,
            });
        } else if (windowWidth < 640) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 2,
                spaceBetween: 20,
            });
        } else if (windowWidth < 769) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 2,
                spaceBetween: 30,
            });
        } else {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 3,
                spaceBetween: 58,
            });
        }
    };

    // Call updateSwiperConfig function when the component mounts
    useEffect(() => {
        updateSwiperConfig();
        window.addEventListener('resize', updateSwiperConfig);
        return () => {
            window.removeEventListener('resize', updateSwiperConfig);
        };
    }, []);

    return (
        <>
            <div className="features ourtest">
                <div className="container-fluid py-3 ">
                    <div className="row">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <small className="color fw-bold py-3 fs-5">Customer Reviews</small>
                            <h1 className="fw-bold text-uppercase">Our Testimonials</h1>
                        </div>
                        <Swiper
                        {...swiperConfig}
                            className="mySwiper py-5 px-5"
                        >
                            <SwiperSlide>
                                <div className="safe step ">
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                    <div className='data text-start'>
                                        <img src={phone} alt="" />
                                        <div className='d-flex flex-column ms-3'>
                                            <h4 className='m-0'>Jems Gilario</h4>
                                            <span>Graphics Designer</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="safe step">
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                    <div className='data  text-start'>
                                        <img src={phone} alt="" />
                                        <div className='d-flex flex-column ms-3'>
                                            <h4 className='m-0'>Jems Gilario</h4>
                                            <span>Graphics Designer</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="safe step">
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                    <div className='data  text-start'>
                                        <img src={phone} alt="" />
                                        <div className='d-flex flex-column ms-3'>
                                            <h4 className='m-0'>Jems Gilario</h4>
                                            <span>Graphics Designer</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="safe step">
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                    <div className='data  text-start'>
                                        <img src={phone} alt="" />
                                        <div className='d-flex flex-column ms-3'>
                                            <h4 className='m-0'>Jems Gilario</h4>
                                            <span>Graphics Designer</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="safe step">
                                    <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                        market</p>
                                    <div className='data  text-start'>
                                        <img src={phone} alt="" />
                                        <div className='d-flex flex-column ms-3'>
                                            <h4 className='m-0'>Jems Gilario</h4>
                                            <span>Graphics Designer</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ourtest