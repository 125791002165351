import React from 'react'
const Privacypolicy = () => {
    return (
        <>
            <div className='privacy'>
                <div className='container'>
                    <div className='row py-5'>
                        <h1 className='text-center'>Privacy Policy</h1>
                    </div>
                </div>
            </div>
            <div className='privacy-intro'>
                <div className='container'>
                    <div className='row py-5'>
                        <h3 className='text-dark'>Introduction</h3>
                        <span className='py-3'>Welcome to the Nano Foundation’s privacy policy.</span>
                        <span className='py-3'>Thank you for using our website, nano.org (the “Site”).</span>
                        <p className='py-3'>The BitcoinNano respects your privacy and is committed to protecting your personal data. 
                        This privacy policy will inform you as to how we look after your personal data when you visit our Site (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
                        <span className='py-3'>By using the Site you accept the practices described in this Privacy Policy.</span>
                    </div>
                    <div className='row py-1'>
                        <h3 className='text-dark'>GDPR notice</h3>
                        <p className='py-3'>When you are located inside the European Economic Area (currently comprised of the European Union countries, including the UK, Iceland, Liechtenstein and Norway) the General Data Protection Regulation (GDPR) also applies. The GDPR does not apply to any person, including citizens of EEA nations, who are located outside of the EEA.</p>
                        <p className='py-3'>If the GDPR applies to you then you have a number of important rights free of charge, which are set out in this policy.</p>
                        <p className='py-3'>This privacy policy is provided in a layered format so that you can click through to the specific areas set out below. Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacypolicy