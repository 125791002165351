import React from 'react'
import exo_sys3 from "../assets/images/RWA.png";
import exo_sys1 from "../assets/images/What-Is-Staking-400x300.png";
import exo_sys2 from "../assets/images/payment.png";

const Ecosystem = () => {
    return (
        <>
            <div className="ecosystembitcoin text-center">
            <div className="container-fluid  py-4">
            <h1 className='text-uppercase border-bb  pb-3 mb-3'>explore bitcoinnano Ecosystem</h1>
                <div className="row cards">
                        <div className="card border-0 p-2 ">
                            <img className='img-fluid' src={exo_sys1} alt="" />
                                <div className="card-body px-2 py-1 text-start">
                                    <h5 className="card-title">Staking To Earn Program</h5>
                                    <ul>
                                        <li>Stake NFT will get NANO</li>
                                        <li>Stake NANO will get NANO</li>
                                        <li>Stake xxx tokens will get NAN0</li>
                                    </ul>
                                </div>
                                <span className="text-end px-2 pb-1">Learn more..</span>
                        </div>
                        <div className="card border-0 p-2">
                            <img src={exo_sys2} alt="" />
                                <div className="card-body px-2 py-1 text-start">
                                    <h5 className="card-title">Staking To Earn Program</h5>
                                    <ul>
                                        <li>Stake NFT will get NANO</li>
                                        <li>Stake NANO will get NANO</li>
                                        <li>Stake xxx tokens will get NAN0</li>
                                    </ul>
                                </div>
                                <span className="text-end px-2 pb-1">Learn more..</span>
                        </div>
                        <div className="card border-0 p-2">
                            <img src={exo_sys3} alt="" />
                                <div className="card-body px-2 py-1 text-start">
                                    <h5 className="card-title">Staking To Earn Program</h5>
                                    <ul>
                                        <li>Stake NFT will get NANO</li>
                                        <li>Stake NANO will get NANO</li>
                                        <li>Stake xxx tokens will get NAN0</li>
                                    </ul>
                                </div>
                                <span className="text-end px-2 pb-1">Learn more..</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ecosystem