import React from 'react'

const Terms = () => {
    return (
        <>
            <div className='privacy'>
                <div className='container'>
                    <div className='row py-5'>
                        <h1 className='text-center'>Terms Of Use</h1>
                    </div>
                </div>
            </div>
            <div className='privacy-intro'>
                <div className='container'>
                    <div className='row py-5 terms'>
                        <h3 className='text-dark text-center text-uppercase '>BITcoinNANO FOUNDATION WEBSITE USER AGREEMENT AND DISCOURSE POLICY</h3>
                    </div>
                    <div className='row'>
                    <span className='py-3'>If you need to see these details in another language, please contact us at &nbsp;<a target='_blank' href='#'>info@bitcoinnano.org &nbsp;</a>with the request</span>
                        <p className='py-3'>Please read these terms and conditions of use carefully before using this site. By accessing or using the site, you agree to be bound by this user agreement. If you do not agree to these terms, do not access, visit and/or use the site.</p>
                        <p className='py-3'>Arbitration notice and class action waiver: You agree that disputes between you and us will be resolved by binding, individual arbitration and you waive your right to participate in a class action lawsuit or class-wide arbitration</p>
                    </div>
                    <div className='row py-1'>
                        <h3 className='text-dark'>What’s in this user agreement ?</h3>
                        <p className='py-3'>These terms tell you the rules for using our website &nbsp;<a target='_blank' href='#'>bitcoinnano.org</a>&nbsp; (the “Site”). It sets out your rights and obligations in accessing, visiting and/or using the Site. We recommend that you retain a copy for your records. You can also access the current User Agreement at any time from the bottom of each page of the Site.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Terms