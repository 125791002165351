// import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Partner from "../components/Partner.jsx";
import Missionvision from "../components/Missionvision.jsx";
import Piechart from "../components/Piechart.jsx";
import Intro from "../components/Intro.jsx";
import Features from "../components/Features.jsx";
import Steps from "../components/Steps.jsx";
import Presale from "../components/Presale.jsx";
import Ecosystem from "../components/Ecosystem.jsx";
import Uniq from "../components/Uniq.jsx";
import Smartphone from "../components/Smartphone.jsx";
import Commingsoon from "../components/Commingsoon.jsx";
import Roadmap from "../components/Roadmap.jsx";
import Ourtest from "../components/Ourtest.jsx";
import Operation from "../components/Operation.jsx";
import Developer from "../components/Developer.jsx";

const Home = () => {
    return (
        <>
            <Intro />
            <Partner />
            <Features />
            <Missionvision />
            <Steps />
            <Piechart />
            <Presale />
            <Ecosystem />
            <Uniq />
            <Smartphone />
            <Commingsoon />
            <Roadmap />
            <Ourtest />
            <Operation />
            <Developer />
        </>
    )
}

export default Home