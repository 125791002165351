import React from 'react'
const invoices = [
  {
    property: "Max Supply",
    value: "21,000,000,000 NANO",
  },
  {
    property: "Presale Tokens",
    value: "2,100,000,000 NANO ",
  },
  {
    property: "Funds Raising Soft Cap & Hard Cap ",
    value:
      "SC = 500 Sol and HC = 1,000 Sol",
  },
  {
    property: "Min & Max Buy",
    value: "Min $0.5 Sol and Max 10 Sol/Wallet",
  },
  {
    property: "Marketplace",
    value: "Solanium.io or TBA",
  },
  {
    property: "Presale live ",
    value: "08th April 2024 at 12.00pm UTC",

  },
  {
    property: "Presale Closed",
    value: "10th April 2024 at 12.00pm UTC",

  },
  {
    property: "Special Package",
    value: "Buy 0.5 Sol, you will get 1 unique NFT (Ex: Buy 5 Sol, you will get 10 unique NFT)",


  },
  {
    property: "Benefits for Unique NFT Holders",
    property: "Benefits for Unique NFT Holders",
    value: [
      "Eligible for the Staking to Earn Program",
      "Eligible for the BitcoinNANO’s Event (Free ticket)",
      "Get a discount 50% of all the trading fee on RWAs Marketplace",
      "Get a revenue share from RWAs Marketplace",
      "Get a discount 20% of the buying Bitcoin’s SmartPhone",
      "Get a revenue share from LP trading provider fee",
      "Get a 25% refund from the LP unlocked"
    ]
  },

];
const Presale = () => {
  return (
    <div className="container py-4" >
      <div className="row  rounddd" data-aos="flip-up" data-aos-duration="3000" >
        <div className="col-md-12 headd p-2 ps-4" >
          <h2 className="fw-bold fs-3 text-white" >Pre sale Information</h2>
        </div>
        {invoices.map((invoice) => (
          <div className=" d-flex ps-4" key={invoice.property}>
            <div className="tablee p-1" >
              <h6 className="fw-bold text-dark">{invoice.property}</h6>
            </div>
            <div className="ps-4 p-1 tablee-2 ">
              {Array.isArray(invoice.value) ? (
                invoice.value.map((val, idx) => (
                  <div key={idx}>{val}</div>
                ))
              ) : (
                <div>{invoice.value}</div>
              )}
              {invoice.value_2 && (
                <div>{invoice.value_2}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Presale