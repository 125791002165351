// import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import Home from "./pages/Home.jsx";
import Missionvision from "./components/Missionvision.jsx";
import Ecosystem from "./components/Ecosystem.jsx";
import Piechart from "./components/Piechart.jsx";
import Privacypolicy from "./pages/Privacypolicy.jsx";
import Terms from "./pages/Terms.jsx";
import Contact from "./pages/Contact.jsx";
import About from "./pages/About.jsx";
const App = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vision" element={<Missionvision />} />
          <Route path="/ecosystem" element={<Ecosystem />} />
          <Route path="chart" element={<Piechart/>} />
          <Route path="/Privacy-Policy" element={<Privacypolicy />} />
          <Route path="/Terms-of-Use" element={<Terms />} />
          <Route path="/Contact-Us" element={<Contact />} />
          <Route path="/About-Us" element={<About />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;