import React from 'react'

const Features = () => {
    return (
        <>
            <div className="features">
                <div className="container py-3" data-aos="flip-left" data-aos-duration="2000">
                    <div className="row">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <small className="color">Why Choose Us</small>
                            <h1 className="fw-bold">Oue Features</h1>
                            <span className="text-center span">There are many variations of passages of ipsum available,but the
                                majority
                                have suffered alteration in some form.</span>
                        </div>
                        <div className="py-4 fea_safe">
                            <div className="safe text-center">
                                <h3>Safe & Secure</h3>
                                <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                    market</p>
                            </div>
                            <div className="safe text-center">
                                <h3>Safe & Secure</h3>
                                <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                    market</p>
                            </div>
                            <div className="safe text-center">
                                <h3>Safe & Secure</h3>
                                <p>Invest in BitcoinNANO on the regular or save with one of the highest inrerest rates on the
                                    market</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features