import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import phone from "../assets/images/Artboard 1 copy 2.jpg";

import devesh from "../assets/images/images devs/AnyConv.com__DnSA5-bB (1).jpg";
import kalinda from "../assets/images/images devs/AnyConv.com__-xHmHdFz.jpg";
import naman from "../assets/images/images devs/AnyConv.com___BrJjYVs.jpg";
import damayanti from "../assets/images/images devs/AnyConv.com__Zl9Vd3Bl.jpg";
import jaya from "../assets/images/images devs/AnyConv.com__0AhXXFsr.jpg";
import samar from "../assets/images/images devs/AnyConv.com__7sRkTtFC.jpg";
import sandeep from "../assets/images/images devs/AnyConv.com__0Sz53JHL.jpg";
import diva from "../assets/images/images devs/AnyConv.com__D_hZ6SlJ.jpg";

const Developer = () => {
    const [swiperConfig, setSwiperConfig] = useState({
        slidesPerView: 4,
        spaceBetween: 58,
        loop: true,
        pagination: {
            clickable: true,
        },
        modules: [Pagination],
    });

    // Function to update Swiper configuration based on window size
    const updateSwiperConfig = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 480) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 1,
                spaceBetween: 10,
            });
        } else if (windowWidth < 640) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 2,
                spaceBetween: 20,
            });
        } else if (windowWidth < 768) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 3,
                spaceBetween: 30,
            });
        } else {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 4,
                spaceBetween: 58,
            });
        }
    };

    // Call updateSwiperConfig function when the component mounts
    useEffect(() => {
        updateSwiperConfig();
        window.addEventListener('resize', updateSwiperConfig);
        return () => {
            window.removeEventListener('resize', updateSwiperConfig);
        };
    }, []);

    return (
        <div className='comming operation px-5 py-5'>
            <h1 className='text-uppercase text-center fw-bold py-1'>Developer Teams</h1>
            <Swiper {...swiperConfig} className='px-2 py-5'>
                {/* Add your SwiperSlide components here */}
                <SwiperSlide>
                    <img src={sandeep} alt="" />
                    <h4 className='m-0'>Mr. Sandeep</h4>
                    <small>Development & Engineer</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={devesh} alt="" />
                    <h4>Mr.Devesh</h4>
                    <small>Web Develop</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={kalinda} alt="" />
                    <h4>Mr. Kalinda</h4>
                    <small>Develop Payment Platform </small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={naman} alt="" />
                    <h4>Mr. Naman</h4>
                    <small>IOS & Adroid Mobile</small>
                    <small>Application</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={diva} alt="" />
                    <h4>Mrs. Diva</h4>
                    <small>Software Engineer</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={damayanti} alt="" />
                    <h4>Mrs. Damayanti</h4>
                    <small>Developer UI & UX</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={jaya} alt="" />
                    <h4>Mrs. Jaya</h4>
                    <small>Develop RWAs</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={samar} alt="" />
                    <h4>Mr. Samar</h4>
                    <small>Develop RWAs</small>
                </SwiperSlide>
                {/* Add more SwiperSlide components as needed */}
            </Swiper>
        </div>
    );
};

export default Developer;
