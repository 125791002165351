import React from 'react'
import phone from "../assets/images/Artboard 1 copy 2.jpg";

const Smartphone = () => {
    return (
        <>
            <div className="smartphone py-4">
                <div className="container">
                    <div className="row" data-aos="zoom-in-down" data-aos-duration="2000">
                        <h1 className="text-center fw-bold text-uppercase">A world class bitcoin smartphone</h1>
                        <div className="py-4  smart_data">
                            <img src={phone} alt="" />
                            <div className="contentt ms-5">
                                <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit incidunt nobis
                                    facilis iure
                                    neque iste architecto corporis temporibus, doloribus, qui consequuntur quo ipsa, facere
                                    repudiandae vel quod laudantium provident officia?</p>
                                <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit incidunt nobis
                                    facilis iure
                                    neque iste architecto corporis temporibus, doloribus, qui consequuntur quo ipsa, facere
                                    repudiandae vel quod laudantium provident officia?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Smartphone