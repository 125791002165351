import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import nanoCoinLogo from "../assets/images/nanoCoin.png";

import phone from "../assets/images/Artboard 1 copy 2.jpg";


import cmo from "../assets/images/images devs/AnyConv.com__DnSA5-bB (1).jpg";
import manoj from "../assets/images/images devs/AnyConv.com__kPBLyXet.jpg";
import mod_leader from "../assets/images/images devs/AnyConv.com__MS2xswW2.jpg";
import mod from "../assets/images/images devs/AnyConv.com__WycQNybB.jpg";
import lucas from "../assets/images/images devs/AnyConv.com__WkTRe9AE.jpg";
import cfo from "../assets/images/images devs/AnyConv.com__stSG6JhY.jpg";



const Operation = () => {
    const [swiperConfig, setSwiperConfig] = useState({
        slidesPerView: 4,
        spaceBetween: 58,
        loop: true,
        pagination: {
            clickable: true,
        },
        modules: [Pagination],
    });

    // Function to update Swiper configuration based on window size
    const updateSwiperConfig = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 480) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 1,
                spaceBetween: 10,
            });
        } else if (windowWidth < 640) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 2,
                spaceBetween: 20,
            });
        } else if (windowWidth < 768) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 3,
                spaceBetween: 30,
            });
        } else {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 4,
                spaceBetween: 58,
            });
        }
    };

    // Call updateSwiperConfig function when the component mounts
    useEffect(() => {
        updateSwiperConfig();
        window.addEventListener('resize', updateSwiperConfig);
        return () => {
            window.removeEventListener('resize', updateSwiperConfig);
        };
    }, []);

    return (
        <div className='comming operation px-5'>
            <h1 className='text-uppercase text-center fw-bold py-1'>Operation Teams</h1>
            <Swiper {...swiperConfig} className='px-2 py-5'>
                {/* Add your SwiperSlide components here */}
                <SwiperSlide>
                    <img src={nanoCoinLogo} alt=""/>
                    <h4 className='m-0'>Mr. Andy LY</h4>
                    <small>Founder/CEO</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={nanoCoinLogo} alt="" />
                    <h4 className='m-0'>Mr. Neza Power</h4>
                    <small>General Assistant</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={cmo} alt="" />
                    <h4 className='m-0'>Available</h4>
                    <small>CMO</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={manoj} alt="" />
                    <h4 className='m-0'>Mr. Manoj Nandanwar</h4>
                    <small>Developer Leader</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={mod_leader} alt="" />
                    <h4 className='m-0'>Mr. Sethnakorn Waichhai</h4>
                    <small>Mod Leader</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={mod} alt="" />
                    <h4 className='m-0'>Available</h4>
                    <small>MOD</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={lucas} alt="" />
                    <h4 className='m-0'>Mr. Lucas</h4>
                    <small>Designer</small>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={cfo} alt="" />
                    <h4 className='m-0'>Available</h4>
                    <small>CFO</small>
                </SwiperSlide>
                {/* Add more SwiperSlide components as needed */}
            </Swiper>
        </div>
    );
};

export default Operation;
