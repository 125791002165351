import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import phone from "../assets/images/Artboard 1 copy 2.jpg";

import 'swiper/css';
import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// import required modules
import { Pagination } from 'swiper/modules';

const Commingsoon = () => {
    const [swiperConfig, setSwiperConfig] = useState({
        slidesPerView: 4,
        spaceBetween: 58,
        loop: true,
        pagination: {
            clickable: true,
        },
        modules: [Pagination],
    });
    const updateSwiperConfig = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 480) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 1,
                spaceBetween: 10,
            });
        } else if (windowWidth < 640) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 2,
                spaceBetween: 20,
            });
        } else if (windowWidth < 768) {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 3,
                spaceBetween: 30,
            });
        } else {
            setSwiperConfig({
                ...swiperConfig,
                slidesPerView: 4,
                spaceBetween: 58,
            });
        }
    };

    // Call updateSwiperConfig function when the component mounts
    useEffect(() => {
        updateSwiperConfig();
        window.addEventListener('resize', updateSwiperConfig);
        return () => {
            window.removeEventListener('resize', updateSwiperConfig);
        };
    }, []);
    return (
        <>
            <div className='comming p-4'>
                <h1 className='text-uppercase text-center fw-bold '>Comming soon</h1>
                <Swiper
                    {...swiperConfig}
                    className="mySwiper px-5 py-5 pt-2"
                >
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={phone} alt="" />
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Commingsoon