import React from 'react'
import bit2 from "../assets/images/22222.gif";
import bit3 from "../assets/images/33.gif";
import bitcoin from "../assets/images/bitcoin.gif";
import bit567 from "../assets/images/567.gif";
const invoices2 = [
    {
        property: "Max Supply",
        value: "2,000 NFT",
    },
    {
        property: "Eligible to get a Unique NFT",
        value: "For whom participated in Presale ",
    },
    {
        property: "Benefits for Unique NFT Holders",
        value: [
            "Eligible for the Staking to Earn Program",
            "Eligible for the BitcoinNANO’s Event (Free ticket)",
            "Get a discount 50% of all the trading fee on RWAs Marketplace",
            "Get a revenue share from RWAs Marketplace",
            "Get a discount 20% of the buying Bitcoin’s SmartPhone",
            "Get a revenue share from LP trading provider fee",
            "Get a 25% refund from the LP unlocked"
        ]
    },

];
const Uniq = () => {
    return (
        <>
            <div className='uniq p-2'>
                <div className="container mb-5 py-4">
                <h1 className='text-center text-uppercase fw-bold pb-4'>Unique nft</h1>
                    <div className="row rounddd" data-aos="flip-up" data-aos-duration="3000" >
                        <div className="headd p-2 ps-4">
                            <h1 className="fw-bold fs-3 text-white pro" >Properties</h1>
                            <h1 className="fw-bold fs-3 text-white value" >Value</h1>
                        </div>
                        {invoices2.map((invoice2) => (
                            <div className=" d-flex ps-3 " key={invoice2.property}>
                                <div className="tablee p-1" >
                                    <h6 className="fw-bold text-dark">{invoice2.property}</h6>
                                </div>
                                <div className="tablee-2 ps-3 p-2">
                                    {Array.isArray(invoice2.value) ? (
                                        invoice2.value.map((val, idx) => (
                                            <div key={idx}>{val}</div>
                                        ))
                                    ) : (
                                        <div>{invoice2.value}</div>
                                    )}
                                    {invoice2.value_2 && (
                                        <div>{invoice2.value_2}</div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container" data-aos="flip-left" data-aos-duration="8002" >
                    <div className="row">
                        <div className="col-md-3 mb-4 d-flex flex-column">
                            <img src={bit2} className="rounded-3 img-fluid" alt="" />
                        </div>
                        <div className="col-md-3 mb-4 d-flex flex-column">
                            <img src={bit3} className="rounded-3 img-fluid" alt="" />
                        </div>
                        <div className="col-md-3 mb-4 d-flex flex-column">
                            <img src={bitcoin} className="rounded-3 img-fluid" alt="" />
                        </div>
                        <div className="col-md-3 mb-4 d-flex flex-column">
                            <img src={bit567} className="rounded-3 img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Uniq