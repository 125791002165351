// import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../assets/images/logoBitcoinnano.png";
const Navbar = () => {
    return (
        <>
            <div className="headerr sticky-top">
                <nav className="navbar navbar-expand-lg navbar-dark justify-content-center py-3">
                    <div className="container-fluid">
                        <div className="logo me-5">
                            <NavLink to=""><img className="pt-1 me-2 img-fluid" alt="logo" src={logo} /></NavLink>
                        </div>
                        <div className="search-box">
                            <button className="btn-search" onclick="toggleLogo()"><i className="bi bi-search"></i></button>
                            <input type="text" className="input-search" placeholder="Type to Search..." />
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto me-3  mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink to="/" className="nav-link text-light " aria-current="page" >Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link text-light " to="/vision">Vision</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link text-light " to="/chart">Tokenomic</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link text-light " to="/ecosystem">Ecosystem</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link text-light" to="#">
                                        Community
                                        <i class="bi bi-caret-down-fill ms-2 text-light fs-6"></i>
                                    </NavLink>
                                    <ul className="dropdown-content p-0">
                                        <li className='nav-link p-0'><NavLink className="dropdown-data" to="/">Twitter</NavLink></li>
                                        <li className='nav-link p-0'><NavLink className="dropdown-data" to="/">Telegram</NavLink></li>
                                        <li className='nav-link p-0'><NavLink className="dropdown-data" to="/">Telegram-2</NavLink></li>
                                    </ul>
                                </li>
                            </ul>
                            <form className="searchh">
                                <input className="form-control w-100" type="search" placeholder="Search" aria-label="Search" />
                            </form>
                            <button className="btn  ms-3 bg-gradient-to-r text-light fw-bold fs-6 text-nowrap px-2">Buy NANO <i class="bi  bi-arrow-right"></i></button>
                        </div>
                    </div>
                </nav>
            </div>

        </>

    );
};

export default Navbar;